import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';
import videoPlaceholder from 'src/assets/images/homepage_placeholder.png';

import { useResponsive } from 'src/hooks/use-responsive';

import { bgGradient } from 'src/theme/css';

import Logo from 'src/components/logo';

// ----------------------------------------------------------------------

export default function AuthModernLayout({ children }) {
  const theme = useTheme();

  const laptopUp = useResponsive('up', 'laptop');

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, laptop: 8 },
      }}
    >
      <Logo
        sx={{
          mt: { xs: 2, laptop: 8 },
          mb: { xs: 10, laptop: 8 },
        }}
      />

      <Card
        sx={{
          py: { xs: 5, laptop: 0 },
          px: { xs: 3, laptop: 0 },
          boxShadow: { laptop: 'none' },
          overflow: { laptop: 'unset' },
          bgcolor: { laptop: 'background.default' },
        }}
      >
        {children}
      </Card>
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      spacing={10}
      alignItems="center"
      justifyContent="center"
      sx={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94
          ),
          imgUrl: '/assets/background/overlay_2.jpg',
        }),
      }}
    >
      <Typography variant="h3" sx={{ maxWidth: 480, textAlign: 'center' }}>
        {'Hi, Welcome back'}
      </Typography>
      <Box
        sx={{
          maxWidth: {
            xs: 480,
            lg: 560,
            xl: 720,
          },
          borderRadius: 8,
          position: 'relative',
        }}
      >
        <video
          poster={videoPlaceholder}
          controls
          width="100%"
          style={{ position: 'relative' }}
        >
          <source
            src="https://d34gluvip3y7kt.cloudfront.net/datapred.mp4"
            type="video/mp4"
          ></source>
        </video>
      </Box>
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
        position: 'relative',
        '&:before': {
          width: 1,
          height: 1,
          zIndex: -1,
          content: "''",
          position: 'absolute',
          backgroundSize: 'cover',
          opacity: { xs: 0.24, laptop: 0 },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundImage: 'url(/assets/background/overlay_4.jpg)',
        },
      }}
    >
      {renderContent}

      {laptopUp && renderSection}
    </Stack>
  );
}

AuthModernLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
};
