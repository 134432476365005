import { useCallback } from 'react';
import jwt_decode from 'jwt-decode';
import { useAuth } from 'src/services/auth/useAuth';
import useDataActions from 'src/services/data/actions';
import { useDataDispatch, useDataState } from 'src/services/data/context';
import useDataQueries from 'src/services/data/queries';
import useAuthActions from './actions';
import { useAuthDispatch } from './context';

export const useTenantManagement = () => {
  const { clientList, loadingStates } = useDataState();

  const dispatch = useAuthDispatch();
  const dataDispatch = useDataDispatch();

  const { loginSucceeded } = useAuthActions(dispatch);
  const { authenticatedRequest, username } = useAuth();

  const { getClientList, setClientActive } =
    useDataQueries(authenticatedRequest);

  const { setMultiData, setLoading } = useDataActions(dataDispatch);

  // Get the list of all the accessible tenant for the user
  const fetchInitialClientList = useCallback(async () => {
    if (!clientList || !clientList.length) {
      setLoading('fetchInitialClientList', true);

      const clientListPromises = getClientList().then((clientList) => {
        return clientList;
      });

      Promise.all([
        clientListPromises.catch((error) => console.error(error)),
      ]).then((values) => {
        setMultiData({
          clientList: values[0],
        });
      });

      setLoading('fetchInitialClientList', false);
    }

  }, [getClientList, setLoading, setMultiData, clientList]);

  // Refresh the access token of the user with the one of his selected tenant
  const onClientActive = useCallback(
    async (id, name) => {
      setLoading('onClientActive', true);
      try {
        const setClientActiveResponse = await setClientActive(id);

        if (setClientActiveResponse?.data?.access) {
          loginSucceeded({
            access: setClientActiveResponse.data.access,
            refresh: setClientActiveResponse.data.refresh,
            username: username,
            clientName: name,
            scopes: jwt_decode(setClientActiveResponse.data.access).scope
              ? jwt_decode(setClientActiveResponse.data.access).scope.split(' ')
              : [],
          });
        }
      } catch (error) {
        setLoading('onClientActive', false);
        throw error;
      }

      setLoading('onClientActive', false);
    },
    [loginSucceeded, setClientActive, setLoading, username]
  );

  return {
    // State
    clientList,
    loadingStates,
    // Actions
    fetchInitialClientList,
    onClientActive,
  };
};
