import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
// import Badge, { badgeClasses } from '@mui/material/Badge';

// import { paths } from 'src/routes/paths';

import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { useAuth } from 'src/services/auth/useAuth';
import { useTenantManagement } from 'src/services/auth/useTenantManagement';
import { useData } from 'src/services/data/useData';
import { paths } from 'src/routes/paths';

import { bgBlur } from 'src/theme/css';

import Logo from 'src/components/logo';
//import Label from 'src/components/label';

import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
import { useSettingsContext } from 'src/components/settings';
import { HEADER } from '../config-layout';
import { navConfig } from './config-navigation';
import HeaderShadow from '../common/header-shadow';
import SettingsButton from '../common/settings-button';
import AccountPopover from '../common/account-popover';
import ReportingGlobalYearSelector from 'src/sections/reporting/reporting-global-year-selector';

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();

  const settings = useSettingsContext();

  const { accessToken, rehydrated, authenticated } = useAuth();
  const [alreadyFetched, setAlreadyFetched] = useState(false);
  const { fetchInitialData, user } = useData();

  const accessTokenScope = accessToken && jwt_decode(accessToken).scope;

  const { pathname } = useLocation();
  const { fetchInitialClientList, clientList } = useTenantManagement();

  const hasRecommendationsAccess = accessTokenScope?.includes(
    'recommendations::read'
  );
  const hasSituationAccess = accessTokenScope?.includes('situation::read');
  const hasMarketAccess = accessTokenScope?.includes('market-trends::read');

  const toolTipText = `This module is not included in your existing license. 
    For more information on the module, please contact us using 
    the chatbot bottom right of this page, or by email at support@datapred.com.`;

  const NAV_ITEMS = [
    {
      subheader: 'Modules',
      items: [
        {
          title: 'Reporting',
          path: hasSituationAccess ? paths.dashboard.reporting : '#',
          caption: hasSituationAccess ? '' : toolTipText,
        },

        {
          title: 'Markets',
          path: hasMarketAccess ? paths.dashboard.market.root : '#',
          caption: hasMarketAccess ? '' : toolTipText,

          children: hasMarketAccess
            ? [
                {
                  title: 'Prices and volatility',
                  path: paths.dashboard.market.pricesAndVolatility,
                },
                {
                  title: 'Analyses and forecasts',
                  path: paths.dashboard.market.analysesAndPredictions,
                },
                {
                  title: 'Price drivers',
                  path: paths.dashboard.market.priceDrivers,
                },
                { divider: true, path: '#' },
                // {
                //   title: 'Scenarios',
                //   path: '#',
                //   //path: paths.dashboard.market.scenarios,
                //   disabled: true,
                // },
                { title: 'Raw Data', path: paths.dashboard.market.rawData },
                {
                  title: 'News and Sentiments',
                  path: paths.dashboard.market.newsAndSentiments,
                },
              ]
            : null,
        },
        {
          title: 'Decisions',
          path: hasRecommendationsAccess ? paths.dashboard.decision.root : '#',
          caption: hasRecommendationsAccess ? '' : toolTipText,
          children: hasRecommendationsAccess
            ? [
                {
                  title: 'Configuration',
                  path: paths.dashboard.decision.configuration,
                },
                { title: 'Program', path: paths.dashboard.decision.program },
                {
                  title: 'Benchmarking',
                  path: paths.dashboard.decision.benchmarking,
                },
              ]
            : null,
        },
      ],
    },
  ];

  const xxlUp = useResponsive('up', 'xxl');

  const mdUp = useResponsive('up', 'md');

  const smDown = useResponsive('down', 'sm');

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  useEffect(() => {
    if (clientList?.length === 0) fetchInitialClientList();
  }, [clientList?.length, fetchInitialClientList]);

  useEffect(() => {
    if (
      rehydrated &&
      authenticated &&
      !alreadyFetched &&
      Object.keys(user).length === 0
    ) {
      fetchInitialData();
      setAlreadyFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rehydrated, alreadyFetched, authenticated]);

  const isTenantSelection = pathname === '/dashboard/accounts';
  const isReporting = pathname === '/dashboard/reporting';

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(xxlUp && {
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            // borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
        }),
      }}
    >
      <Toolbar disableGutters>
        <Container
          sx={{
            height: 1,
            display: 'flex',
            alignItems: 'center',
            gap: { lMobile: 0, tablet: 3, laptop: 5 },
          }}
          maxWidth={false}
        >
          <Logo />

          {mdUp && !isTenantSelection && <NavDesktop data={NAV_ITEMS} />}
          <Box sx={{ flexGrow: 1 }} />
          {!smDown && isReporting && <ReportingGlobalYearSelector />}

          <Stack
            alignItems="flex-start"
            direction={{ xs: 'row', md: 'row-reverse' }}
          >
            <Stack
              flexGrow={1}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={{ xs: 0.5, sm: 1 }}
            >
              <SettingsButton />
              <AccountPopover />
            </Stack>
            {!mdUp && !isTenantSelection && <NavMobile data={navConfig} />}
          </Stack>
        </Container>
      </Toolbar>

      <HeaderShadow />
    </AppBar>
  );
}
