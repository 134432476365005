import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS

export const grey = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

export const primary = {
  lighter: '#FEEDEF',
  light: '#EDA5A0',
  main: '#EC6C5B',
  dark: '#E25A42',
  darker: '#C24B36',
  contrastText: '#FFFFFF',
};

export const secondary = {
  lighter: '#EFD6FF',
  light: '#C684FF',
  main: '#8E33FF',
  dark: '#5119B7',
  darker: '#27097A',
  contrastText: '#FFFFFF',
};

export const info = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
};

export const success = {
  lighter: '#D3FCD2',
  light: '#77ED8B',
  main: '#22C55E',
  dark: '#118D57',
  darker: '#065E49',
  contrastText: '#ffffff',
};

export const warning = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: grey[800],
};

export const error = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

export const common = {
  black: '#000000',
  white: '#FFFFFF',
};

export const chartCategorical = {
  azuraRadiance: '#147AF3',
  java: '#0FB5AE',
  ectasy: '#F68511',
  cerise: '#DE3D82',
  governorBay: '#4046CA',
  pastelGreen: '#72E06A',
  cornflowerBlue: '#7E84FA',
  seaGreen: '#2f8255',
  tenn: '#CB5D00',
  corn: '#E8C600',
  pear: '#bae641',
};

export const legacyApexChart = [
  primary.main,
  warning.main,
  info.main,
  error.main,
  success.main,
  warning.dark,
  success.darker,
  info.dark,
  info.darker,
];
export const sequential = {
  deepViolet: '#49006A',
  blueViolet: '#7A0177',
  eminence: '#AE017E',
  maximumPurple: '#DD3497',
  darkLavender: '#F768A1',
  frenchLilacSolid: '#FA9FB5',
  frenchLilac: '#FCC5C0',
  RegalBlue: '#004969',
  Orient: '#005f82',
  Allports: '#00739b',
  BondiBlue: '#0087b5',
  PacificBlue: '#009bce',
  Cerulean: '#00aff0',
  RobinsEggBlue: '#00c3ee',
  Nandor: '#526760',
  ViridianGreen: '#6c8579',
  Envy: '#86a492',
  SpringRain: '#a1c2ab',
  Ziggurat: '#bbddde',
};

export const sequentialColorBliness = {
  // https://mk.bcgsc.ca/colorblind/palettes.mhtml#24-color-palette-for-colorbliness
  sherwoodGreen: '#003D30',
  darkSpringGreen: '#005745',
  bottleGreen: '#00735C',
  elfGreen: '#009175',
  greenyBlue: '#00AF8E',
  eucalyptus: '#00CBA7',
  brilliantTurquoise: '#00EBC1',
  paleTurquoise: '#86FFDE',
  darkishBlue: '#00306F',
  toryBlue: '#00489E',
  royalBlue: '#005FCC',
  slateBlue: '#0079FA',
  darkSkyBlue: '#009FFA',
  cyan: '#00C2F9',
  aquaBlue: '#00E5F8',
  electricBlue: '#7CFFFA',
  forestGreen: '#004002',
  darkGreen: '#005A01',
  webGreen: '#007702',
  grassGreen: '#009503',
  limeGreen: '#00B408',
  greenApple: '#00D302',
  neonGreen: '#00F407',
  yellowgreen: '#AFFF2A',
};

export const sequentialColorBlinessExtension = {
  crimson: '#DC143C',
  tomato: '#FF6347',
  coralRed: '#FF6F61',
  lightCoral: '#F08080',
  deepPurple: '#800080',
  royalPurple: '#6A0DAD',
  amethyst: '#9966CC',
  heliotrope: '#DF73FF',
  slateGray: '#708090',
  warmTaupe: '#9E775C',
  lightGray: '#D3D3D3',
  charcoal: '#36454F',
  goldenYellow: '#FFD700',
  saffron: '#FFA700',
  pumpkin: '#FF8500',
  amber: '#FFB000',
};

export const categoricalColorBlindness = {
  // 40 colors
  amber: '#FFB000',
  mintGreen: '#78C679',
  neutralGray: '#9E9E9E',
  royalPurple: '#8B008B',
  coral: '#F95D6A',
  forestGreen: '#00441B',
  dustyPink: '#E8A2AF',
  crimson: '#DC143C',
  mutedPurple: '#665191',
  lemonYellow: '#FFFFCC',
  pumpkin: '#FF8500',
  lavender: '#CAB2D6',
  electricBlue: '#7DF9FF',
  amethyst: '#9966CC',
  tomato: '#FF6347',
  coolGray: '#3E3E3E',
  mossGreen: '#006D2C',
  saffron: '#FFA700',
  rose: '#D45087',
  jungleGreen: '#238443',
  royalBlue: '#005FCC',
  goldenYellow: '#FFD700',
  steelBlue: '#2F4B7C',
  brightTurquoise: '#00CED1',
  salmon: '#FA8072',
  paleGreen: '#D9F0A3',
  deepPurple: '#6A3D9A',
  paleRed: '#F08080',
  charcoal: '#6F6F6F',
  slateBlue: '#0079FA',
  fireBrick: '#B22222',
  mutedOrange: '#FF7C43',
  cyan: '#00C2F9',
  platinum: '#E0E0E0',
  orange: '#FFA600',
  emerald: '#41AB5D',
  aqua: '#00E5F8',
  deepBlue: '#003F5C',
  limeGreen: '#ADDD8E',
  lemon: '#FFE66D',
  orchidPurple: '#A05195',
};

export const action = {
  hover: alpha(grey[500], 0.08),
  selected: alpha(grey[500], 0.16),
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[500], 0.24),
  focus: alpha(grey[500], 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
};

const base = {
  primary,
  secondary,
  info,
  success,
  warning,
  error,
  grey,
  common,
  divider: alpha(grey[500], 0.2),
  action,
};

// ----------------------------------------------------------------------

export function palette(mode) {
  const light = {
    ...base,
    mode: 'light',
    text: {
      primary: grey[800],
      secondary: grey[600],
      disabled: grey[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: grey[200],
    },
    action: {
      ...base.action,
      active: grey[600],
    },
  };

  const dark = {
    ...base,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: grey[500],
      disabled: grey[600],
    },
    background: {
      paper: grey[800],
      default: grey[900],
      neutral: alpha(grey[500], 0.12),
    },
    action: {
      ...base.action,
      active: grey[500],
    },
  };

  return mode === 'light' ? light : dark;
}
