import { lazy, Suspense } from 'react';

import { AuthGuard } from 'src/auth/guard';
import MainLayout from 'src/layouts/main';

import { LoadingScreen } from 'src/components/loading-screen';
import { PrivateRoute } from 'src/pages/privateRoute';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/reporting'));
const MarketsPage = lazy(() => import('src/pages/dashboard/markets'));
const AccountsPage = lazy(() => import('src/pages/dashboard/accounts'));
const DecisionsPage = lazy(() => import('src/pages/dashboard/decisions'));
const ReportingPage = lazy(() => import('src/pages/dashboard/reporting'));
const SettingsPage = lazy(() => import('src/pages/dashboard/settings'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <MainLayout>
          <Suspense fallback={<LoadingScreen />}>
            <PrivateRoute />
          </Suspense>
        </MainLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'reporting', element: <ReportingPage /> },
      { path: 'accounts', element: <AccountsPage /> },
      {
        path: 'market',
        children: [{ element: <MarketsPage />, index: true }],
      },
      {
        path: 'decision',
        children: [{ element: <DecisionsPage />, index: true }],
      },
      {
        path: 'settings',
        children: [{ element: <SettingsPage />, index: true }],
      },
    ],
  },
];
