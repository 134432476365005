import { toPng } from 'html-to-image';
import jsPDF from 'jspdf';
import moment from 'moment';
import * as echarts from 'echarts';
import ApexCharts from 'apexcharts';
import { merge } from 'lodash';
import { fontPublicSansBold, fontPublicSansNormal } from './customPDFFonts';

const prod_domains = [
  // SaaS:
  'app.datapred.com',
  // On-prem:
  // (nothing yet)
];
export const isDev = !prod_domains.includes(window.location.hostname);

export const chartIcons = {
  zoomIn:
    'path://M0 0h24v24H0z M3 5v4h2V5h4V3H5c-1.1 0-2 .9-2 2zm2 10H3v4c0 1.1.9 2 2 2h4v-2H5v-4zm14 4h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4zm0-16h-4v2h4v4h2V5c0-1.1-.9-2-2-2z',
  zoomOut:
    'path://M0 0h24v24H0z M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z',
};

export const sortArrayOfObjectsById = (arr) => {
  const clonedArray = Array.from(arr);

  clonedArray.sort(function (a, b) {
    return a.id - b.id;
  });
  return clonedArray;
};

export const sortArrayOfObjectsByStringKeyAsc = (arr, key = '') =>
  arr.sort((a, b) => (b[key] < a[key]) - (b[key] > a[key]));

export const sortArrayOfObjectsByDateKey = (arr, key = '') => {
  const clonedArray = Array.from(arr);

  clonedArray.sort(function (a, b) {
    return Date.parse(a[key]) - Date.parse(b[key]);
  });
  return clonedArray;
};

export const toggleInArray = (arr, item, idKey) => {
  // If the item is an object, pass the 'idKey'
  if (idKey) {
    const itemFound = !!arr.find((i) => i[idKey] === item[idKey]);
    if (itemFound) {
      return arr.filter((i) => {
        return i[idKey] !== item[idKey];
      });
    }
    return [...arr, item];
  }
  // If the item is string or number
  return arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item];
};

export const getStartFromEndWithPeriod = (
  period,
  from,
  samplingFrequency = '1D'
) => {
  if (samplingFrequency === '1MS') {
    if (!period || period === '7D') {
      return moment(from).subtract(7, 'days').startOf('month');
    } else if (period === '30D') {
      return moment(from).subtract(30, 'days').startOf('month');
    } else if (period === '90D') {
      return moment(from).subtract(90, 'days').startOf('month');
    } else if (period === '1Y') {
      return moment(from).subtract(1, 'years').startOf('month');
    } else if (period === '5Y') {
      return moment(from).subtract(5, 'years').startOf('month');
    } else {
      // "All"
      return moment(new Date()).subtract(10, 'years');
    }
  } else {
    if (!period || period === '7D') {
      return moment(from).subtract(7, 'days');
    } else if (period === '30D') {
      return moment(from).subtract(30, 'days');
    } else if (period === '90D') {
      return moment(from).subtract(90, 'days');
    } else if (period === '1Y') {
      return moment(from).subtract(1, 'years');
    } else if (period === '5Y') {
      return moment(from).subtract(5, 'years');
    } else {
      // "All"
      return moment(new Date()).subtract(10, 'years');
    }
  }
};

export const getEndFromStartWithPeriod = (period, from) => {
  if (!period || period === '7D') {
    return moment(from).add(7, 'days');
  } else if (period === '30D') {
    return moment(from).add(30, 'days');
  } else if (period === '90D') {
    return moment(from).add(90, 'days');
  } else if (period === '1Y') {
    return moment(from).add(1, 'years');
  } else if (period === '5Y') {
    return moment(from).add(5, 'years');
  } else {
    // "All"
    return moment(new Date()).add(10, 'years');
  }
};

export const getStartFromMiddleWithPeriod = (
  period,
  from,
  samplingFrequency = '1D'
) => {
  if (samplingFrequency === '1MS') {
    if (!period || period === '7D') {
      return moment(from).subtract(4, 'days');
    } else if (period === '30D') {
      return moment(from).subtract(15, 'days').startOf('month');
    } else if (period === '90D') {
      return moment(from).subtract(45, 'days').startOf('month');
    } else if (period === '1Y') {
      return moment(from).subtract(0.5, 'years').startOf('month');
    } else if (period === '5Y') {
      return moment(from).subtract(2.5, 'years').startOf('month');
    } else {
      // "All"
      return moment(new Date()).subtract(5, 'years');
    }
  } else {
    if (!period || period === '7D') {
      return moment(from).subtract(4, 'days');
    } else if (period === '30D') {
      return moment(from).subtract(15, 'days');
    } else if (period === '90D') {
      return moment(from).subtract(45, 'days');
    } else if (period === '1Y') {
      return moment(from).subtract(0.5, 'years');
    } else if (period === '5Y') {
      return moment(from).subtract(2.5, 'years');
    } else {
      // "All"
      return moment(new Date()).subtract(5, 'years');
    }
  }
};

export const getEndFromMiddleWithPeriod = (period, from) => {
  if (!period || period === '7D') {
    return moment(from).add(4, 'days');
  } else if (period === '30D') {
    return moment(from).add(15, 'days');
  } else if (period === '90D') {
    return moment(from).add(45, 'days');
  } else if (period === '1Y') {
    return moment(from).add(0.5, 'years');
  } else if (period === '5Y') {
    return moment(from).add(2.5, 'years');
  } else {
    // "All"
    return moment(new Date()).add(5, 'years');
  }
};

export const getMatrixMaxValue = (data = [], key) =>
  Math.max(...data.map((item) => item[key]));

export const getMatrixMinValue = (data = [], key) =>
  Math.min(...data.map((item) => item[key]));

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const groupBy = (objectArray, property) => {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
};

export const generatePdfName = (type, marketName, date = new Date()) => {
  const formatedDate = moment(date).format('YYYYMMDD');
  const formatedMarketName = marketName.replace(/[\s-]+/g, '');
  const pdfName = `${type}_${formatedMarketName}_${formatedDate}`;
  return pdfName;
};

// Temp fix for Safari pdf generation
const safariFakeLoadingNodeToPdfBis = async (node) => {
  const elements = document.querySelectorAll(node);
  for (const element of elements) {
    await toPng(element);
    await toPng(element);
  }
};

function svgToDataURL(svgElement, rootElement) {
  const width = 1600;
  const height = 900;
  // Serialize the SVG to a string
  const copySvgElement = svgElement.cloneNode(true);
  copySvgElement.setAttribute('width', '100%');
  copySvgElement.setAttribute('height', '100%');
  copySvgElement.setAttribute('preserveAspectRatio', 'none');
  rootElement.appendChild(copySvgElement);
  const serializer = new XMLSerializer();
  const svgString = serializer.serializeToString(copySvgElement);

  // Create an image element
  const img = new Image();
  const svgBlob = new Blob([svgString], {
    type: 'image/svg+xml;charset=utf-8',
  });
  const url = URL.createObjectURL(svgBlob);

  return new Promise((resolve, reject) => {
    img.onload = () => {
      // Create a canvas element
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');

      // Draw the SVG onto the canvas
      ctx.drawImage(img, 0, 0);

      // Convert canvas to a data URL (Base64)
      const dataURL = canvas.toDataURL('image/png');
      resolve(dataURL);

      // Cleanup
      URL.revokeObjectURL(url);
    };

    img.onerror = (err) => reject(err);
    img.src = url;
  });
}

export const exportChartToPdf = async (
  node,
  name = 'charts',
  title = null,
  // includeChartTopLabels boolean refers to DOM Elements that are above the actual chart (e.g: stats bar label, Price Drivers charts titles etc.)
  includeChartTopLabels = false,
  // includeChartBottomLabels boolean refers to DOM Elements that are below the actual chart (e.g: "Strong buy" label, "Confidence interval" label etc.)
  includeChartBottomLabels = false
) => {
  // Check is the used browser is Safari
  if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
    await safariFakeLoadingNodeToPdfBis(node);
  }

  const userAgent =
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    );

  const elements = document.querySelectorAll(node);

  //if no charts to export then no export
  if (elements.length === 0) {
    return;
  }

  const pdfPageHeight = 900;
  const pdfPageWidth = 1600;
  const titleHeight = 80,
    statsBarHeight = 80;
  const titleLeftMargin = 20;
  const bottomLabelHeight = 60;
  const elementMargin = 10;

  let imgHeight = pdfPageHeight;
  if (includeChartTopLabels && includeChartBottomLabels) {
    imgHeight =
      pdfPageHeight - titleHeight - statsBarHeight - bottomLabelHeight;
  } else if (includeChartTopLabels) {
    imgHeight = pdfPageHeight - titleHeight - statsBarHeight;
  } else if (includeChartBottomLabels) {
    imgHeight = pdfPageHeight - titleHeight - bottomLabelHeight;
  }

  const doc = new jsPDF('l', 'px', [pdfPageHeight, pdfPageWidth]);
  const offscreenChartContainer = document.createElement('div');
  offscreenChartContainer.setAttribute('id', 'hidden-chart');
  offscreenChartContainer.setAttribute(
    'style',
    `height: ${pdfPageHeight}px; width: ${pdfPageWidth}px; position: absolute; top: 0px; left: -10000px; display: flex; flex-direction: row; align-items: center;`
  );
  // Draw the off-screen container before rendering the chart
  const rootEl = document.getElementById('root');
  rootEl.appendChild(offscreenChartContainer);
  let imgData = null;

  // Add fonts
  doc.addFileToVFS('PublicSansBold.ttf', fontPublicSansBold);
  doc.addFont('PublicSansBold.ttf', 'Public Sans', 'bold');
  doc.setFont('Public Sans');
  doc.addFileToVFS('PublicSansNormal.ttf', fontPublicSansNormal);
  doc.addFont('PublicSansNormal.ttf', 'Public Sans', 'normal');
  doc.setFont('Public Sans');

  // Add the title
  await doc.html(
    `<h1 style="font-family: Public Sans; font-weight: bold;">${title}</h1>`,
    {
      callback: (doc) => {
        return doc;
      },
      x: titleLeftMargin,
      y: 0,
      width: pdfPageWidth,
      windowWidth: pdfPageWidth,
    }
  );

  for (const element of elements) {
    // Check if chart is eCharts or Apexcharts instance
    const echartsInstance = echarts.getInstanceByDom(element.children[0]);
    const apexChartsInstance = ApexCharts.getChartByID(`${node.slice(1)}`);

    if (echartsInstance) {
      // Re-instantiate the chart so we don't alter the chart that is visible on the screen
      const fakeEchartsInstance = echarts.init(offscreenChartContainer);

      fakeEchartsInstance.setOption({
        ...echartsInstance.getOption(),
        // Disable animations, so that the chart is fully drawn by the time we're calling getDataURL
        animation: false,
      });
      fakeEchartsInstance.setOption({
        grid: [
          {
            height: '70%',
            width: '95%',
          },
        ],
        legend: [
          {
            height: '30%',
            width: '99%',
            bottom: '10px',
            // Since we use the custom legend, we explicitly specify to show the integrated eCharts legend
            show: true,
          },
        ],
      });

      if (includeChartTopLabels) {
        const statsBarElement = element.parentElement
          .querySelector('.stats-bar')
          .cloneNode(true);
        if (isMobile) {
          // Change the layout from Hamburger menu to the Desktop one
          statsBarElement.setAttribute(
            'style',
            'background-color: rgb(244, 246, 248); color: #212B36; '
          );

          Array.from(statsBarElement.children).map((child) => {
            child.setAttribute('style', 'flex: 1; padding: 12px;');
            child.children[0].setAttribute('style', 'align-items: flex-start');
            statsBarElement.appendChild(child);
          });
        }

        await doc.html(statsBarElement, {
          callback: (doc) => {
            return doc;
          },
          x: 0,
          y: titleHeight,
          width: pdfPageWidth,
          windowWidth: pdfPageWidth,
        });
      }

      imgData = fakeEchartsInstance.getDataURL({
        pixelRatio: 1,
        backgroundColor: '#fff',
        excludeComponents: ['toolbox'],
      });

      doc.addImage(
        imgData,
        'PNG',
        0,
        includeChartTopLabels ? titleHeight + statsBarHeight : titleHeight,
        pdfPageWidth,
        imgHeight - elementMargin * 2
      );

      fakeEchartsInstance.dispose();
    } else if (apexChartsInstance) {
      const chartOptions = merge(
        {
          // Chart
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            animations: { enabled: false },
          },

          // States
          states: {
            hover: {
              filter: {
                type: 'lighten',
                value: 0.04,
              },
            },
            active: {
              filter: {
                type: 'darken',
                value: 0.88,
              },
            },
          },

          // Fill
          fill: {
            opacity: 1,
            gradient: {
              type: 'vertical',
              shadeIntensity: 0,
              opacityFrom: 0.4,
              opacityTo: 0,
              stops: [0, 100],
            },
          },

          // Stroke
          stroke: {
            width: 3,
            curve: 'smooth',
            lineCap: 'round',
          },

          // Tooltip
          tooltip: {
            theme: true,
            x: {
              show: true,
              format: 'dd MMM yyyy',
            },
          },

          // plotOptions
          plotOptions: {
            // Bar
            bar: {
              columnWidth: '6.5%',
            },
            // Pie + Donut
            pie: {
              dataLabels: {
                offset: -25,
              },
            },
          },

          // Responsive
        },
        {
          annotations: {
            ...apexChartsInstance.w.config.annotations,
          },
          chart: {
            ...apexChartsInstance.w.config.chart,
            id: 'hiddenChart',
            width: `${pdfPageWidth}px`,
            height: `${imgHeight}px`,
            animations: {
              enabled: false,
            },
          },
          colors: [...apexChartsInstance.w.config.colors],
          dataLabels: {
            ...apexChartsInstance.w.config.dataLabels,
          },
          fill: {
            ...apexChartsInstance.w.config.fill,
          },
          yaxis: [...apexChartsInstance.w.config.yaxis],
          xaxis: {
            ...apexChartsInstance.w.config.xaxis,
          },
          tooltip: {
            ...apexChartsInstance.w.config.tooltip,
          },
          responsive: [...apexChartsInstance.w.config.responsive],
          legend: {
            ...apexChartsInstance.w.config.legend,
          },
          markers: {
            ...apexChartsInstance.w.config.markers,
          },
          grid: {
            ...apexChartsInstance.w.config.grid,
          },
          series: [...apexChartsInstance.w.config.series],
        }
      );
      const fakeApexChartsInstance = new ApexCharts(offscreenChartContainer, {
        ...chartOptions,
      });

      await fakeApexChartsInstance.render();

      if (includeChartTopLabels) {
        const statsBarElement = element
          .querySelector('.stats-bar')
          .cloneNode(true);

        if (isMobile) {
          statsBarElement.setAttribute(
            'style',
            'background-color: rgb(244, 246, 248); color: #212B36; '
          );

          Array.from(statsBarElement.children).map((child) => {
            child.setAttribute('style', 'flex: 1; padding: 12px;');
            child.children[0].setAttribute('style', 'align-items: flex-start');
            statsBarElement.appendChild(child);
          });
        }

        await doc.html(statsBarElement, {
          callback: (doc) => {
            return doc;
          },
          x: 0,
          y: titleHeight,
          width: pdfPageWidth,
          windowWidth: pdfPageWidth,
        });
      }

      const { imgURI } = await fakeApexChartsInstance.dataURI();

      imgData = imgURI;

      doc.addImage(
        imgData,
        'PNG',
        0,
        includeChartTopLabels ? titleHeight + statsBarHeight : titleHeight,
        pdfPageWidth,
        imgHeight
      );

      if (includeChartBottomLabels) {
        const bottomLabelElement = element.querySelector('.bottom-label');

        await doc.html(bottomLabelElement, {
          callback: (doc) => {
            return doc;
          },
          x: 0,
          y:
            titleHeight +
            (includeChartTopLabels ? statsBarHeight : 0) +
            imgHeight +
            elementMargin * 2,
          width: pdfPageWidth,
          windowWidth: pdfPageWidth,
        });
      }

      fakeApexChartsInstance.destroy();
    } else if (node === '.CorridorChart') {
      // Price corridors - Recharts

      const svgElement = element.querySelector('svg');
      imgData = await svgToDataURL(svgElement, offscreenChartContainer);

      doc.addImage(imgData, 'PNG', 100, titleHeight, 1400, 720);

      if (includeChartBottomLabels) {
        const nodeClone = element
          .querySelector('.bottom-label')
          .cloneNode(true);
        const cloneSVG = nodeClone.querySelector('svg');
        // Remove the tooptip icon svg
        nodeClone.children[0].removeChild(cloneSVG);

        Array.from(nodeClone.children).map((child, index) => {
          if (index === 0) {
            child.setAttribute(
              'style',
              'font-size: 1.275rem; font-weight: bold'
            );
          } else {
            const pEl = child.querySelector('p');
            pEl.setAttribute('style', 'font-size: 1.275rem;');
          }
        });

        await doc.html(nodeClone, {
          callback: (doc) => {
            return doc;
          },
          x: 0,
          y: pdfPageHeight - bottomLabelHeight,
          width: pdfPageWidth,
          windowWidth: pdfPageWidth,
        });
      }
    } else {
      // Markets -> Price Drivers
      if (includeChartTopLabels) {
        await doc.html(
          `<div style="display: flex; width: 1600px; background-color: rgb(244, 246, 248);">
            <div style="display: flex; flex: 1.5">
              <p style="padding-left: 20px; color: #637381;
    font-weight: 600; font-family: Public Sans, sans-serif;">Current Price Drivers</p>
            </div>
            <div style="display: flex; flex: 2.5">
              <p style="padding-left: 20px; color: #637381;
    font-weight: 600; font-family: Public Sans, sans-serif;">Evolution Price Drivers</p>
            </div>
          </div>`,
          {
            callback: (doc) => {
              return doc;
            },
            x: 0,
            y: titleHeight,
            width: pdfPageWidth,
            windowWidth: pdfPageWidth,
          }
        );
      }

      const fakeApexChartsInstance = new ApexCharts(offscreenChartContainer, {
        chart: { type: 'line' },
      });

      const chartInstances = fakeApexChartsInstance.w.config._chartInstances;

      if (!chartInstances || !chartInstances.length) return;

      const currentPriceDriversChartContainer = document.createElement('div');
      currentPriceDriversChartContainer.setAttribute(
        'id',
        'hidden-chart-current-price-drivers'
      );
      currentPriceDriversChartContainer.setAttribute(
        'style',
        'display: flex; flex: 1.5'
      );

      const evolutionPriceDriversChartContainer = document.createElement('div');
      evolutionPriceDriversChartContainer.setAttribute(
        'id',
        'hidden-chart-evolution-price-drivers'
      );
      evolutionPriceDriversChartContainer.setAttribute(
        'style',
        'display: flex; flex: 2.5'
      );

      offscreenChartContainer.appendChild(currentPriceDriversChartContainer);
      offscreenChartContainer.appendChild(evolutionPriceDriversChartContainer);

      const fakeCurrentPriceDriversChartInstance = new ApexCharts(
        currentPriceDriversChartContainer,
        ApexCharts.merge(Object.assign({}, chartInstances[0].chart.w.config), {
          chart: {
            id: 'hiddenCurrentPriceDrivers',
            animations: { enabled: false },
            width: '100%',
            height: '60%',
          },
          dataLabels: {
            enabled: true,
            formatter: function (value) {
              return value !== 0 && value >= 2 ? `${value.toFixed(0)}%` : '';
            },
          },
        })
      );

      const fakeEvolutionPriceDriversChartInstance = new ApexCharts(
        evolutionPriceDriversChartContainer,
        ApexCharts.merge(Object.assign({}, chartInstances[1].chart.w.config), {
          chart: {
            id: 'hiddenEvolutionPriceDrivers',
            animations: { enabled: false },
            width: '100%',
            height: '60%',
          },
          legend: {
            height: '100%',
            itemMargin: {
              vertical: 10,
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (value) {
              return value !== 0 && value >= 2 ? `${value.toFixed(0)}%` : '';
            },
          },
        })
      );

      await fakeCurrentPriceDriversChartInstance.render();
      await fakeEvolutionPriceDriversChartInstance.render();

      const { height: EPDChartContainerHeight, width: EPDChartContainerWidth } =
        evolutionPriceDriversChartContainer.getBoundingClientRect();
      const { height: CPDChartContainerHeight, width: CPDChartContainerWidth } =
        currentPriceDriversChartContainer.getBoundingClientRect();

      const { imgURI: currentPriceDriversPNG } =
        await fakeCurrentPriceDriversChartInstance.dataURI();
      const { imgURI: evolutionPriceDriversPNG } =
        await fakeEvolutionPriceDriversChartInstance.dataURI();

      doc.addImage(
        currentPriceDriversPNG,
        'PNG',
        -10,
        (EPDChartContainerHeight - CPDChartContainerHeight) / 2 +
          titleHeight +
          statsBarHeight,
        CPDChartContainerWidth - 10,
        CPDChartContainerHeight - 10
      );
      doc.addImage(
        evolutionPriceDriversPNG,
        'PNG',
        CPDChartContainerWidth + 10,
        titleHeight + statsBarHeight,
        EPDChartContainerWidth - 20,
        EPDChartContainerHeight
      );

      await ApexCharts.getChartByID('hiddenEvolutionPriceDrivers').destroy();
      await ApexCharts.getChartByID('hiddenCurrentPriceDrivers').destroy();
      rootEl.removeChild(document.getElementById('hidden-chart'));
      doc.save(`${name}.pdf`);

      return;
    }
  }

  rootEl.removeChild(document.getElementById('hidden-chart'));
  doc.save(`${name}.pdf`);
};

export const exportNodeToPdf = async (node, name = 'charts') => {
  // Check is the used browser is Safari
  if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
    await safariFakeLoadingNodeToPdfBis(node);
  }
  const sidePadding = 20;
  let pdfHeight = 20;
  let pdfMaxWidth = 0;
  // top position of image insert
  let top = 20;
  const elements = document.querySelectorAll(node);
  //if no charts to export then no export
  if (elements.length === 0) {
    return;
  }
  // get height and width needed for PDF document
  elements.forEach((element) => {
    pdfHeight += element.offsetHeight;
    element.offsetWidth > pdfMaxWidth && (pdfMaxWidth = element.offsetWidth);
    pdfHeight += 20;
  });

  const doc =
    pdfMaxWidth > pdfHeight
      ? new jsPDF('l', 'px', [pdfMaxWidth, pdfHeight])
      : new jsPDF('p', 'px', [pdfMaxWidth, pdfHeight]);

  let aliasIndex = 1;
  const pageWidth = doc.internal.pageSize.getWidth() - sidePadding * 2;

  for (const element of elements) {
    let elHeight = element.offsetHeight;
    let elWidth = element.offsetWidth;
    const data = await toPng(element);
    if (elWidth > pageWidth) {
      const ratio = pageWidth / elWidth;
      // resize chart width and heigth proportionally
      elHeight = elHeight * ratio;
      elWidth = elWidth * ratio;
    }
    doc.addImage(
      data,
      'PNG',
      sidePadding,
      top,
      elWidth,
      elHeight,
      `alias-${aliasIndex}`
    );
    top = top + elHeight + 20;
    aliasIndex++;
  }
  doc.save(`${name}.pdf`);
};

// Export target #table_id into a csv; compatible with MUI table
export const downloadTableAsCsv = (table_id, separator = ',') => {
  // Select rows from table_id
  var rows = document.querySelectorAll('table#' + table_id + ' tr');
  // Construct csv
  var csv = [];
  for (var i = 0; i < rows.length; i++) {
    var row = [],
      cols = rows[i].querySelectorAll('td, th');
    for (var j = 0; j < cols.length; j++) {
      // Clean innertext to remove multiple spaces and jumpline (break csv)
      var data = cols[j].innerText
        .replace(/(\r\n|\n|\r)/gm, '')
        .replace(/(\s\s)/gm, ' ');
      // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
      data = data.replace(/"/g, '""');
      // Push escaped string
      row.push('"' + data + '"');
    }
    csv.push(row.join(separator));
  }
  var csv_string = csv.join('\n');
  // Download it
  var filename =
    'export_' + table_id + '_' + new Date().toLocaleDateString() + '.csv';
  var link = document.createElement('a');
  link.style.display = 'none';
  link.setAttribute('target', '_blank');
  link.setAttribute(
    'href',
    'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string)
  );
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// Add thousands spaces to number
export const formatNumberWithThousandsSpaces = (number) => {
  const reversedNumber = number.toString().split('').reverse();

  let formattedNumber = [];
  for (let i = 0; i < reversedNumber.length; i++) {
    if (i % 3 === 0 && i !== 0) {
      formattedNumber.push(' ');
    }
    formattedNumber.push(reversedNumber[i]);
  }
  return formattedNumber.reverse().join('');
};

export function abbreviateLargeNumbers(number, numberOfDecimals) {
  if (number >= 1e9) {
    return (number / 1e9).toFixed(numberOfDecimals) + 'b';
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(numberOfDecimals) + 'm';
  } else if (number >= 1e3) {
    return (number / 1e3).toFixed(numberOfDecimals) + 'k';
  } else if (number === 0) {
    return 0;
  } else {
    return number.toFixed(numberOfDecimals);
  }
}

// Convert number to this format '1 234 567.89'
export const formatNumberToTwoNumbersFloatWithThousandsSpaces = (number) => {
  const splittedValue = number.toFixed(2).toString().split('.');
  const decimalPart = splittedValue[1];
  const reversedIntegerPart = splittedValue[0].split('').reverse();

  let formattedIntegerPart = [];
  for (let i = 0; i < reversedIntegerPart.length; i++) {
    if (i % 3 === 0 && i !== 0) {
      formattedIntegerPart.push(' ');
    }
    formattedIntegerPart.push(reversedIntegerPart[i]);
  }
  return formattedIntegerPart.reverse().join('') + '.' + decimalPart;
};

export const getFutureYearFromDate = (date, years) => {
  const futureDay = moment(date).add(years, 'year');
  const futureYear = futureDay.get('year');
  return futureYear;
};
export const getPastYearFromDate = (date, years) => {
  const pastDay = moment(date).subtract(years, 'year');
  const pastYear = pastDay.get('year');
  return pastYear;
};

export const generateSituationPeriods = (selectedSituationDate) => {
  const situationPeriods = [
    {
      id: 'Y-1',
      label: getPastYearFromDate(selectedSituationDate, 1).toString(),
    },
    { id: 'Y', label: moment(selectedSituationDate).get('year').toString() },
    {
      id: 'Y+1',
      label: getFutureYearFromDate(selectedSituationDate, 1).toString(),
    },
    {
      id: 'Y+2',
      label: getFutureYearFromDate(selectedSituationDate, 2).toString(),
    },
    {
      id: 'Y+3',
      label: getFutureYearFromDate(selectedSituationDate, 3).toString(),
    },
    {
      id: 'dateRange',
      label: 'Date Range',
      isDateRange: true,
    },
  ];
  return situationPeriods;
};
export const generateBenchmarkingDashboardPeriods = (selectedSituationDate) => {
  const situationPeriods = [
    {
      id: 'Y-4',
      label: getPastYearFromDate(selectedSituationDate, 4).toString(),
    },
    {
      id: 'Y-3',
      label: getPastYearFromDate(selectedSituationDate, 3).toString(),
    },
    {
      id: 'Y-2',
      label: getPastYearFromDate(selectedSituationDate, 2).toString(),
    },
    {
      id: 'Y-1',
      label: getPastYearFromDate(selectedSituationDate, 1).toString(),
    },
    { id: 'Y', label: moment(selectedSituationDate).get('year').toString() },

    { id: 'all', label: 'All' },
    {
      id: 'dateRange',
      label: 'Date range',
      isDateRange: true,
    },
  ];
  return situationPeriods;
};

export const formatDateToEndOfDay = (date) =>
  moment.utc(date).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');

export const isCurrency = (currencyShortcode) => {
  const isoCurrcency = [
    'EUR',
    'USD',
    'GBP',
    'NOK',
    'PLN',
    'DKK',
    'SEK',
    'MYR',
    'JPY',
    'CNY',
  ];
  return isoCurrcency.includes(currencyShortcode);
};

/* To Title Case © 2018 David Gouch | https://github.com/gouch/to-title-case */
// package doesn't seems to be maintained
export const toTitleCase = (str) => {
  const smallWords =
    /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|off|on|or|per|so|the|to|up|v.?|vs.?|via|yet)$/i;
  const alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/;
  const wordSeparators = /([ :–—-])/;

  return str
    .split(wordSeparators)
    .map(function (current, index, array) {
      if (
        /* Check for small words */
        current.search(smallWords) > -1 &&
        /* Skip first and last word */
        index !== 0 &&
        index !== array.length - 1 &&
        /* Ignore title end and subtitle start */
        array[index - 3] !== ':' &&
        array[index + 1] !== ':' &&
        /* Ignore small words that start a hyphenated phrase */
        (array[index + 1] !== '-' ||
          (array[index - 1] === '-' && array[index + 1] === '-'))
      ) {
        return current.toLowerCase();
      }

      /* Ignore intentional capitalization */
      if (current.substr(1).search(/[A-Z]|\../) > -1) {
        return current;
      }

      /* Ignore URLs */
      if (array[index + 1] === ':' && array[index + 2] !== '') {
        return current;
      }

      /* Capitalize the first letter */
      return current.replace(alphanumericPattern, function (match) {
        return match.toUpperCase();
      });
    })
    .join('');
};
//export const valueLabelFormat = (value) => `${value * 100}%`;
export const valueLabelFormat = (value) => `${Math.round(value * 100)}%`;

//Trading rules dates validation

const months = {
  Jan: 0,
  Feb: 1,
  Mar: 2,
  Apr: 3,
  May: 4,
  Jun: 5,
  Jul: 6,
  Aug: 7,
  Sep: 8,
  Oct: 9,
  Nov: 10,
  Dec: 11,
};

const parseDate = (day, yearOffset) => {
  const [dayOfMonth, month] = day.split(' ');
  const currentYear = new Date().getFullYear();
  const year = currentYear + yearOffset;
  return new Date(year, months[month], parseInt(dayOfMonth));
};

export const validateDatesYear = (startDay, startYear, endDay, endYear) => {
  const startDate = parseDate(startDay, startYear);
  const endDate = parseDate(endDay, endYear);
  return endDate >= startDate;
};
export const validateYears = (startYear, endYear) => {
  return endYear >= startYear;
};

export const validateCoveragesDates = (startDay, endDay, year) => {
  const startDate = parseDate(startDay, 0);
  const endDate = parseDate(endDay, year);
  return endDate >= startDate;
};

export const validateMinMax = (minValue, maxValue) => {
  return minValue >= maxValue;
};
export const hashCode = (str) => {
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32-bit integer
  }
  return hash;
};
