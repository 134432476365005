import { useState, useCallback, useMemo, useEffect } from 'react';
import { ButtonBase, MenuItem, Typography, Stack } from '@mui/material';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useSituation } from 'src/services/situation/useSituation';
import { useData } from 'src/services/data/useData';
import moment from 'moment';

export default function ReportingGlobalYearSelector() {
  const {
    situationPeriods,
    marketDemands,
    selectedMarket,
    getUpdatedSituationData,
  } = useSituation();

  const { setMultiData } = useData();

  const popover = usePopover();

  // Remove dateRange from the available periods
  const periods = useMemo(() => [...situationPeriods], [situationPeriods]);
  const indexDateRange = periods.findIndex(
    (period) => period.id === 'dateRange'
  );
  if (indexDateRange !== -1) periods.splice(indexDateRange, 1);

  const initialTabIndex = periods.indexOf(
    situationPeriods.find((p) => p.id === 'Y')
  );

  const defaultPeriod = periods[initialTabIndex];
  const [selectedPeriod, setSelectedPeriod] = useState(defaultPeriod);

  const handleGlobalChangePeriod = useCallback(
    (period) => {
      popover.onClose();
      setSelectedPeriod(period);

      // update global year selected in reducer
      setMultiData({
        selectedGlobalSituationYear: period,
        marketDemandSelectedPeriod: period,
      });

      // Fetch updated data for Summary dashboard
      const startDate = moment(period.label)
        .startOf('year')
        .format('YYYY-MM-DD');
      const endDate = moment(period.label).endOf('year').format('YYYY-MM-DD');
      const targetPrice =
        marketDemands[0]?.situationTargetPrice?.history.find(
          (elem) => moment(elem.start_date).year() === Number(period.label)
        )?.value || 0;

      getUpdatedSituationData(startDate, endDate, targetPrice);
    },
    [getUpdatedSituationData, marketDemands, popover, setMultiData]
  );

  useEffect(() => {
    if (defaultPeriod) setSelectedPeriod(defaultPeriod);
  }, [defaultPeriod, selectedMarket]);

  return (
    <Stack
      component="nav"
      direction="row"
      alignItems="center"
      spacing={5}
      sx={{ mr: 2.5, height: 1 }}
    >
      <ButtonBase
        onClick={popover.onOpen}
        sx={{
          pl: 2,
          py: 1.5,
          pr: 1,
          mt: 0,
          maxHeight: 0.7,
          borderRadius: 1,
          boxShadow:
            '0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)',
          typography: 'subtitle1',
          border: '1px solid rgba(145, 158, 171, 0.32)',
          bgcolor: '#FFFFFF',
          textAlign: 'left',
          pointerEvents: 'all',
          gap: '8px',
        }}
      >
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Reporting Year
        </Typography>
        <Typography
          // variant="h6"
          sx={{ fontSize: '1rem', fontWeight: 'bold', mt: 0.25 }}
        >
          {selectedPeriod?.label || defaultPeriod?.label}
        </Typography>

        <Iconify
          width={16}
          icon={
            popover.open
              ? 'eva:arrow-ios-upward-fill'
              : 'eva:arrow-ios-downward-fill'
          }
          sx={{ ml: 1 }}
        />
      </ButtonBase>
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 140 }}
      >
        {periods.map((period) => (
          <MenuItem
            key={period.id}
            selected={period?.label === selectedPeriod?.label}
            onClick={() => handleGlobalChangePeriod(period)}
          >
            {period?.label}
          </MenuItem>
        ))}
      </CustomPopover>
    </Stack>
  );
}
