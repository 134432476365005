// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  ACCOUNTS: '/accounts',
};

// ----------------------------------------------------------------------

export const paths = {
  page403: '/403',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
      forgotPassword: `${ROOTS.AUTH}/jwt/forgot-password`,
      verify: `${ROOTS.AUTH}/jwt/verify`,
      passwordReset: `${ROOTS.AUTH}/jwt/password-reset`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    accounts: `${ROOTS.DASHBOARD}/accounts`,
    reporting: `${ROOTS.DASHBOARD}/reporting`,
    market: {
      root: `${ROOTS.DASHBOARD}/market`,

      pricesAndVolatility: `${ROOTS.DASHBOARD}/market?tabId=1`,
      analysesAndPredictions: `${ROOTS.DASHBOARD}/market?tabId=2`,
      priceDrivers: `${ROOTS.DASHBOARD}/market?tabId=3`,
      // scenarios: `${ROOTS.DASHBOARD}/market?tabId=4`,
      rawData: `${ROOTS.DASHBOARD}/market?tabId=5`,
      newsAndSentiments: `${ROOTS.DASHBOARD}/market?tabId=6`,
    },
    decision: {
      root: `${ROOTS.DASHBOARD}/decision`,
      configuration: `${ROOTS.DASHBOARD}/decision?tabId=1`,
      program: `${ROOTS.DASHBOARD}/decision?tabId=2`,
      benchmarking: `${ROOTS.DASHBOARD}/decision?tabId=3`,
    },
    settings: {
      root: `${ROOTS.DASHBOARD}/settings`,
      userManagement: `${ROOTS.DASHBOARD}/settings?tabId=1`,
      notifications: `${ROOTS.DASHBOARD}/settings?tabId=2`,
    },
    three: `${ROOTS.DASHBOARD}/three`,
    group: {
      five: `${ROOTS.DASHBOARD}/group/five`,
      root: `${ROOTS.DASHBOARD}/group`,
      six: `${ROOTS.DASHBOARD}/group/six`,
    },
  },
  //ACCOUNTS
};
